import React, { useEffect, useState } from 'react'
import { ConnectedEventActionButton } from '../../components/EventActionButton/EventActionButton'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import { RootState } from '../../store'
import { EditorState, EditorValues } from '../../store/editor'
import { constants } from '../../constants'
import { splitSubEvents } from '../../utils/formDataMapping'
import { scrollToTop } from '../../utils/helpers'
import { hasOrganizationWithRegularUsers } from '../../utils/user'
import { useParams } from 'react-router'
import { executeSendRequest } from '../../actions/editor'

const { SUPER_EVENT_TYPE_UMBRELLA, PUBLICATION_STATUS, USER_TYPE } = constants

export const EditorButtons: React.FC<{
  event: EditorValues
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  subEvents: any[]
  loading: boolean
}> = ({ event, subEvents, loading }) => {
  const [isRegularUser, setIsRegularUser] = useState(false)

  const { action } = useParams<{ action: string }>()
  const editMode = action
  const isDraft = event.publication_status === PUBLICATION_STATUS.DRAFT
  const dispatch = useDispatch()
  const user = useSelector((state: RootState) => state.user)
  const editor: EditorState = useSelector((state: RootState) => state.editor)
  const isUmbrellaEvent =
    editor.values?.super_event_type === SUPER_EVENT_TYPE_UMBRELLA

  const hasNewSubEvents =
    editor.values?.sub_events &&
    Object.keys(splitSubEvents(editor.values?.sub_events).newSubEvents).length >
      0
  const hasSubEvents = subEvents && subEvents.length > 0

  useEffect(() => {
    const isRegularUser = user?.userType === USER_TYPE.REGULAR
    setIsRegularUser(isRegularUser)
  }, [user])

  const handleConfirmedAction = (
    action: 'delete' | 'cancel',
    event: { id: string; publication_status: string }
  ) => {
    const isDraft = event.publication_status === PUBLICATION_STATUS.DRAFT

    // navigate to moderation if an admin deleted a draft event, otherwise navigate to event listing
    if (action === 'delete') {
      if (isDraft && hasOrganizationWithRegularUsers(user)) {
        dispatch(push('/moderation'))
      } else {
        dispatch(push('/'))
      }
    }
    // navigate to event view after cancel action
    if (action === 'cancel') {
      dispatch(push(`/event/${event.id}`))
      scrollToTop()
    }
  }

  /**
   * Returns a button for the given action
   * @param action            Action to run
   * @param customAction      Custom action that should be run instead of the default one
   * @param confirm           Whether confirmation modal should be shown before running action
   * @param customButtonLabel ID of text to use as button label
   * @returns {*}   */
  const getActionButton = (
    action: string,
    // Promise is because of executeSendRequest. You should delete Promise from here.
    customAction?: (() => void) | (() => Promise<void>),
    confirm = true,
    customButtonLabel?: string
  ) => (
    <ConnectedEventActionButton
      action={action}
      askConfirmation={confirm}
      customAction={customAction}
      customButtonLabel={customButtonLabel}
      event={event}
      eventIsPublished={eventIsPublished()}
      loading={loading || editor.loading}
      runAfterAction={handleConfirmedAction}
      subEvents={subEvents}
    />
  )

  const eventIsPublished = () => {
    if (action !== 'update') {
      // we are not updating an existing event
      return false
    }
    const publicationStatus = editor?.values?.publication_status
    if (!publicationStatus) {
      // if the field is missing, the user is not logged in, so the event is public
      return true
    }
    if (publicationStatus === PUBLICATION_STATUS.PUBLIC) {
      return true
    }
    // the publication status field exists and the event is not public
    return false
  }

  /**
   * Saves the editor changes
   */
  const saveChanges = async (): Promise<void> => {
    const updateExisting = action === 'update'
    const publicationStatus =
      isRegularUser ||
      (editor.superEventOrg &&
        !user.adminOrganizations?.includes(editor.superEventOrg))
        ? PUBLICATION_STATUS.DRAFT
        : PUBLICATION_STATUS.PUBLIC

    dispatch(
      await executeSendRequest(
        editor.values,
        updateExisting,
        publicationStatus,
        subEvents
      )
    )
  }

  /**
   * Saves the editor changes to a draft event without publishing
   */
  const saveChangesToDraft = async (): Promise<void> => {
    dispatch(
      await executeSendRequest(
        editor.values,
        true,
        PUBLICATION_STATUS.DRAFT,
        subEvents
      )
    )
  }

  const navigateToModeration = () => dispatch(push('/moderation'))

  return (
    <div className="buttons-group container">
      {/* TODO: split getActionButton is separate actionbutton components */}
      {editMode === 'update' && getActionButton('cancel')}
      {editMode === 'update' && getActionButton('delete')}
      {isDraft &&
        hasOrganizationWithRegularUsers(user) &&
        getActionButton('return', navigateToModeration, false)}
      {
        // button that saves changes to a draft without publishing
        // only shown to moderators
        isDraft &&
          hasOrganizationWithRegularUsers(user) &&
          getActionButton(
            'update-draft',
            saveChangesToDraft,
            hasSubEvents && !isUmbrellaEvent,
            'event-action-save-draft-existing'
          )
      }
      {
        // show confirmation modal when the updated event has sub events and isn't an umbrella event,
        // otherwise save directly, and use update with delete when neeeded
        getActionButton(
          editor.superEventOrg &&
            !user?.adminOrganizations?.includes(editor.superEventOrg)
            ? 'update-draft'
            : hasNewSubEvents
              ? 'update-and-delete'
              : 'update',
          saveChanges,
          hasSubEvents && !isUmbrellaEvent
        )
      }
    </div>
  )
}
