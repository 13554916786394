import React, { useEffect, useState } from 'react'
import { Button } from '@material-ui/core'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  appendEventDataWithSubEvents,
  deleteAPIEvents,
  getEventDataFromIds,
  getEventsWithSubEvents,
  publishEvents,
} from '../../utils/events'
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal'
import { getConfirmationMarkup } from '../../utils/confirm'
import { APIEvent } from '../../types/apiTypes'

const DraftActionButton: React.FC<{
  action: string
  // TODO proper types
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  events: any[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedRows: any[]
  runAfterAction: () => void
}> = ({ action, events, selectedRows, runAfterAction }) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [eventData, setEventData] = useState<APIEvent[]>([])
  const intl = useIntl()

  const buttonColor = action === 'delete' ? 'secondary' : 'primary'

  useEffect(() => {
    const fetchEventData = async (): Promise<void> => {
      const selectedEventsData = getEventDataFromIds(selectedRows, events)
      const eventsWithSubEvents = getEventsWithSubEvents(selectedEventsData)
      const data =
        eventsWithSubEvents.length > 0
          ? await appendEventDataWithSubEvents(
              selectedEventsData,
              eventsWithSubEvents
            )
          : selectedEventsData
      setEventData(data)
    }
    fetchEventData()
  }, [events, selectedRows])

  const runConfirmAction = (): void => {
    if (action === 'delete') {
      deleteAPIEvents(eventData).then(runAfterAction)
    } else {
      publishEvents(eventData).then(runAfterAction)
    }
    setShowConfirmationModal(false)
  }

  const extraMessage = action === 'cancel' ? 'confirm-cancel-extra' : null
  const warningMessage = `${action}-draft`
  const modalStyle =
    action === 'cancel' || action === 'delete' ? 'warning' : 'message'

  return (
    <>
      <Button
        key={`draft-${action}-button`}
        variant="contained"
        color={buttonColor}
        className="draft-actions--button"
        disabled={selectedRows.length === 0}
        onClick={() => setShowConfirmationModal(true)}
      >
        {selectedRows.length > 1 ? (
          <>
            <FormattedMessage id={`${action}-events-multi`} />
            <span className="draft-actions--button-count">{`(${selectedRows.length})`}</span>
          </>
        ) : (
          <FormattedMessage id={`${action}-events`} />
        )}
      </Button>
      {showConfirmationModal && (
        <ConfirmationModal
          msg={`confirm-${action}-draft`}
          style={modalStyle}
          actionButtonLabel={`${action}-events`}
          cancel={() => setShowConfirmationModal(false)}
          confirm={runConfirmAction}
          additionalMarkup={getConfirmationMarkup(
            extraMessage,
            warningMessage,
            intl,
            eventData
          )}
        />
      )}
    </>
  )
}

export default DraftActionButton
