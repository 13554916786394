import { each, isEmpty, omitBy, pickBy, remove } from 'lodash'
import { constants } from '../constants'
import { validationRules } from './validationRules'

const { VALIDATION_RULES, PUBLICATION_STATUS } = constants

// Validations for draft
const draftValidations = {
  name: [
    VALIDATION_RULES.REQUIRE_MULTI,
    VALIDATION_RULES.REQUIRED_IN_CONTENT_LANGUAGE,
  ],
  location: [VALIDATION_RULES.REQUIRE_AT_ID],
  date_published: [
    VALIDATION_RULES.BEFORE_START_TIME,
    VALIDATION_RULES.IN_THE_FUTURE,
    VALIDATION_RULES.IS_SERVER_ERROR,
  ],
  start_time: [
    VALIDATION_RULES.IS_DATE,
    VALIDATION_RULES.DEFAULT_END_IN_FUTURE,
    VALIDATION_RULES.IS_SERVER_ERROR,
  ],
  end_time: [VALIDATION_RULES.AFTER_START_TIME, VALIDATION_RULES.IN_THE_FUTURE],
  price: [VALIDATION_RULES.HAS_PRICE], // Is this rule ever used? The price rule is defined in validateOffers
  short_description: [
    VALIDATION_RULES.REQUIRE_MULTI,
    VALIDATION_RULES.REQUIRED_IN_CONTENT_LANGUAGE,
    VALIDATION_RULES.SHORT_STRING,
  ],
  description_html: [
    VALIDATION_RULES.REQUIRE_MULTI,
    VALIDATION_RULES.REQUIRED_IN_CONTENT_LANGUAGE,
    VALIDATION_RULES.LONG_STRING,
  ],
  info_url: [VALIDATION_RULES.IS_URL], // Is this rule ever used? The info_url rule is defined in validateOffers
  extlink_facebook: [VALIDATION_RULES.IS_URL],
  extlink_twitter: [VALIDATION_RULES.IS_URL],
  extlink_instagram: [VALIDATION_RULES.IS_URL],
  event_registration_link: [VALIDATION_RULES.IS_URL],
  audience_min_age: [
    VALIDATION_RULES.IS_INT,
    VALIDATION_RULES.IS_HOBBY_WITH_NO_AGE_LIMITS,
  ],
  audience_max_age: [
    VALIDATION_RULES.IS_INT,
    VALIDATION_RULES.IS_HOBBY_WITH_NO_AGE_LIMITS,
  ],
  enrolment_end_time: [
    VALIDATION_RULES.AFTER_ENROLMENT_START_TIME,
    VALIDATION_RULES.IN_THE_FUTURE,
  ],
  minimum_attendee_capacity: [VALIDATION_RULES.IS_INT],
  maximum_attendee_capacity: [VALIDATION_RULES.IS_INT],
}

// Validations for published event
const publicValidations = {
  name: [
    VALIDATION_RULES.REQUIRE_MULTI,
    VALIDATION_RULES.REQUIRED_IN_CONTENT_LANGUAGE,
  ],
  location: [VALIDATION_RULES.REQUIRE_AT_ID],
  date_published: [
    VALIDATION_RULES.BEFORE_START_TIME,
    VALIDATION_RULES.IS_SERVER_ERROR,
  ],
  start_time: [
    VALIDATION_RULES.IS_DATE,
    VALIDATION_RULES.DEFAULT_END_IN_FUTURE,
    VALIDATION_RULES.IS_SERVER_ERROR,
  ], // Datetime is saved as ISO string
  end_time: [
    VALIDATION_RULES.AFTER_START_TIME,
    VALIDATION_RULES.IN_THE_FUTURE,
    VALIDATION_RULES.IS_SERVER_ERROR,
  ],
  price: [VALIDATION_RULES.HAS_PRICE], // Is this rule ever used? The price rule is defined in validateOffers
  short_description: [
    VALIDATION_RULES.REQUIRE_MULTI,
    VALIDATION_RULES.REQUIRED_IN_CONTENT_LANGUAGE,
    VALIDATION_RULES.SHORT_STRING,
  ],
  description_html: [
    VALIDATION_RULES.REQUIRE_MULTI,
    VALIDATION_RULES.REQUIRED_IN_CONTENT_LANGUAGE,
    VALIDATION_RULES.LONG_STRING,
  ],
  info_url: [VALIDATION_RULES.IS_URL], // Is this rule ever used? The info_url rule is defined in validateOffers
  extlink_facebook: [VALIDATION_RULES.IS_URL],
  extlink_twitter: [VALIDATION_RULES.IS_URL],
  extlink_instagram: [VALIDATION_RULES.IS_URL],
  event_registration_link: [VALIDATION_RULES.IS_URL],
  sub_events: {
    start_time: [VALIDATION_RULES.IS_DATE],
    end_time: [VALIDATION_RULES.AFTER_START_TIME],
  },
  keywords: [
    // TODO: reimplement validation logic for keywords
    // VALIDATION_RULES.AT_LEAST_ONE_TOPIC,
  ],
  placeKeywords: [VALIDATION_RULES.AT_LEAST_ONE_PLACE_KEYWORD],
  audience_min_age: [
    VALIDATION_RULES.IS_INT,
    VALIDATION_RULES.IS_HOBBY_WITH_NO_AGE_LIMITS,
  ],
  audience_max_age: [
    VALIDATION_RULES.IS_INT,
    VALIDATION_RULES.IS_HOBBY_WITH_NO_AGE_LIMITS,
  ],
  minimum_attendee_capacity: [VALIDATION_RULES.IS_INT],
  maximum_attendee_capacity: [VALIDATION_RULES.IS_INT],
}

const validateOffers = (values) => {
  const { offers } = values

  if (!offers) {
    return null
  }

  const priceField = 'price'
  const descriptionField = 'description'
  const infoUrlField = 'info_url'

  // validation rules used for the offer fields
  const offerValidationRules = {
    [priceField]: VALIDATION_RULES.HAS_PRICE,
    [descriptionField]: VALIDATION_RULES.LONG_STRING,
    [infoUrlField]: VALIDATION_RULES.IS_URL,
  }
  // prepends key names with prefix where applicable
  const prependKeyName = (key) => {
    const offerPrefix = 'offer_'

    return key === 'info_url' || key === 'description'
      ? `${offerPrefix}${key}`
      : key
  }

  const validOfferField = (field, offer, validationRule) => {
    // The offer object might not contain all keys so that's why we must explicitly check for the keys
    switch (field) {
      case priceField:
        // The price field is required
        return (
          Object.prototype.hasOwnProperty.call(offer, field) &&
          validationRules[validationRule](values, offer, field)
        )
      case descriptionField:
        // The description field is optional
        return (
          !Object.prototype.hasOwnProperty.call(offer, field) ||
          validationRules[validationRule](offer, offer[field], field)
        )
      case infoUrlField:
        // The info url field is optional
        return (
          !Object.prototype.hasOwnProperty.call(offer, field) ||
          validationRules[validationRule](values, offer, field)
        )
      default:
        return undefined
    }
  }

  const errors = {}

  // loop through all offers and get validation errors for each one
  offers.forEach((offer, index) => {
    // Loop through the fields that need to be validated
    const offerValidationErrors = [
      priceField,
      descriptionField,
      infoUrlField,
    ].reduce((acc, field) => {
      const validationRule = offerValidationRules[field]

      if (validationRule && !validOfferField(field, offer, validationRule)) {
        acc[prependKeyName(field)] = [
          { key: `${index}`, validation: validationRule },
        ]
      }
      return acc
    }, {})

    // add validation errors to the errors object
    each(offerValidationErrors, (value, key) => {
      if (Object.getOwnPropertyNames(errors).includes(key)) {
        errors[key][0].push(...value)
      } else {
        errors[key] = [value]
      }
    })
  })
  return errors
}

const formatValidation = (key, validation) => {
  if (validation === 'isServerError') {
    switch (key) {
      case 'start_time':
        return 'server-super-event-start-time-mismatch'
      case 'end_time':
        return 'server-super-event-end-time-mismatch'
      case 'date_published':
        return 'server-date-published-mismatch'
    }
  }
  return validation
}

function runValidationWithSettings(values, languages, settings, keywordSets) {
  let obj = {}

  // Add content languages to values to have them available in the validations
  const valuesWithLanguages = {
    ...values,
    contentLanguages: languages,
  }

  each(settings, (validations, key) => {
    // Returns an array of validation errors (array of nulls if validation passed)
    let errors = []
    // validate sub events
    if (key === 'sub_events') {
      errors = {}
      each(values.sub_events, (subEvent, eventKey) => {
        const subEventError = runValidationWithSettings(
          subEvent,
          languages,
          settings.sub_events
        )
        const error = isEmpty(subEventError) ? null : subEventError
        errors[eventKey] = error
      })
      // validate offers
    } else if (key === 'price') {
      errors = validateOffers(valuesWithLanguages)
      // validate keywords
    } else if (key === 'keywords') {
      errors = validations.map((validation) =>
        validationRules[validation](
          valuesWithLanguages,
          valuesWithLanguages[key],
          keywordSets
        )
          ? null
          : validation
      )
    } else if (key === 'placeKeywords') {
      errors = validations.map((validation) =>
        validationRules[validation](
          valuesWithLanguages,
          valuesWithLanguages[key],
          keywordSets
        )
          ? null
          : validation
      )
    } else {
      errors = validations.map((validation) =>
        validationRules[validation](
          valuesWithLanguages,
          valuesWithLanguages[key],
          key
        )
          ? null
          : formatValidation(key, validation)
      )
    }

    // Remove nulls
    if (key === 'sub_events') {
      errors = omitBy(errors, (i) => i === null)
    } else {
      remove(errors, (i) => i === null)
    }

    // handle offers separately
    if (key === 'price') {
      obj = { ...obj, ...errors }
    } else {
      obj[key] = errors
    }
  })
  obj = pickBy(obj, (validationErrors, key) => {
    if (key === 'sub_events') {
      return !isEmpty(validationErrors)
    }
    return validationErrors.length > 0
  })
  return obj
}

/**
 * Run draft/public validations depending which document
 * @return {object} Validation errors object
 */
export const doValidations = (values, languages, validateFor, keywordSets) => {
  // Public validations
  if (validateFor === PUBLICATION_STATUS.PUBLIC) {
    const errors = runValidationWithSettings(
      values,
      languages,
      publicValidations,
      keywordSets
    )
    return errors
  }

  if (validateFor === PUBLICATION_STATUS.DRAFT) {
    // Do draft validations
    return runValidationWithSettings(
      values,
      languages,
      draftValidations,
      keywordSets
    )
  }

  return {}
}
