import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Checkbox,
  CircularProgress,
  TableCell,
  TableRow,
} from '@material-ui/core'
import { get, isEmpty, isUndefined } from 'lodash'
import { constants } from '../../constants'
import { NameCell } from './CellTypes/NameCell'
import { DateTimeCell } from './CellTypes/DateTimeCell'
import { PublisherCell } from './CellTypes/PublisherCell'
import { getFirstMultiLanguageFieldValue } from '../../utils/helpers'
import { EventQueryParams, fetchEvents } from '../../utils/events'
import { ConnectedValidationCell } from './CellTypes/ValidationCell'

const { SUPER_EVENT_TYPE_RECURRING, SUPER_EVENT_TYPE_UMBRELLA } = constants

class EventRow extends React.Component {
  state = {
    subEvents: [],
    showSubEvents: false,
    isSubEvent: false,
    isSuperEvent: false,
    superEventType: null,
    isFetching: false,
    rowInvalid: false,
  }

  componentDidMount() {
    const { event } = this.props
    const superEventType = event.super_event_type
    // whether the event is a super event
    const isSuperEvent =
      superEventType === SUPER_EVENT_TYPE_RECURRING ||
      superEventType === SUPER_EVENT_TYPE_UMBRELLA
    // whether the event is a sub event
    const isSubEvent = !isUndefined(get(event, ['super_event', '@id']))

    this.setState({ isSubEvent, isSuperEvent, superEventType })
  }

  toggleSubEvent = async () => {
    const { event } = this.props
    const { subEvents, showSubEvents, isSuperEvent } = this.state

    if (!showSubEvents && isSuperEvent && isEmpty(subEvents)) {
      this.setState({ isFetching: true, showSubEvents: !showSubEvents })

      const queryParams = new EventQueryParams()
      queryParams.super_event = event.id
      queryParams.include = 'keywords'
      queryParams.page_size = 100
      // with sub events, also show drafts if the user has the right to see them
      queryParams.show_all = true
      // with sub events, fetch *all*, including sub events contributed by other organizations!
      // this way, the admin notices there are sub events even if they have no rights to edit them.

      try {
        const response = await fetchEvents(queryParams)
        this.setState({ subEvents: response.data.data })
      } finally {
        this.setState({ isFetching: false })
      }
    } else {
      this.setState({ showSubEvents: !showSubEvents })
    }
  }

  /**
   * Handles invalid rows
   * @param eventId
   */
  handleInvalidRow = (eventId) => {
    const { event, tableName, handleInvalidRows } = this.props
    const { rowInvalid } = this.state

    if (eventId === event.id && !rowInvalid) {
      handleInvalidRows(event, tableName)
      this.setState({ rowInvalid: true })
    }
  }

  getPublisherData = () => {
    const { event, user } = this.props
    const publisherId = get(event, ['publisher'], '')
    const publisher = get(
      user,
      ['adminOrganizationData', publisherId, 'name'],
      ''
    )
    const createdBy = get(event, 'created_by', '')
    const eventName = getFirstMultiLanguageFieldValue(event.name)

    return { publisher, createdBy, eventName }
  }

  render() {
    const {
      event,
      nestLevel,
      tableName,
      tableColumns,
      selectedRows,
      invalidRows,
      handleRowSelect,
      superEventIsChecked,
    } = this.props

    const {
      subEvents,
      showSubEvents,
      isSubEvent,
      isSuperEvent,
      superEventType,
      isFetching,
      rowInvalid,
    } = this.state

    const hasSubEvents = get(event, 'sub_events', []).length > 0
    const shouldShow = showSubEvents && isSuperEvent
    const checked = isSubEvent
      ? superEventIsChecked || selectedRows.includes(event.id)
      : selectedRows.includes(event.id)
    const disabled = rowInvalid || invalidRows.includes(event.id)

    return (
      <React.Fragment>
        <TableRow
          className={isSubEvent ? 'sub-event-row' : ''}
          selected={checked}
        >
          {tableColumns.map((type, index) => {
            if (type === 'checkbox') {
              return (
                <TableCell
                  key={`${event.id}-cell-${index}`}
                  className="checkbox"
                >
                  <Checkbox
                    color="primary"
                    checked={checked}
                    disabled={disabled}
                    onChange={(e, checked2) =>
                      handleRowSelect(checked2, event.id, tableName)
                    }
                  />
                </TableCell>
              )
            }
            if (type === 'name') {
              return (
                <NameCell
                  key={`${event.id}-cell-${index}`}
                  event={event}
                  nestLevel={nestLevel}
                  isSuperEvent={isSuperEvent}
                  superEventType={superEventType}
                  hasSubEvents={hasSubEvents}
                  showSubEvents={showSubEvents}
                  toggleSubEvent={this.toggleSubEvent}
                />
              )
            }
            if (type === 'publisher') {
              return (
                <PublisherCell
                  key={`${event.id}-cell-${index}`}
                  {...this.getPublisherData()}
                />
              )
            }
            if (type === 'start_time') {
              return (
                <DateTimeCell
                  key={`${event.id}-cell-${index}`}
                  event={event}
                  start
                />
              )
            }
            if (type === 'end_time') {
              return (
                <DateTimeCell
                  key={`${event.id}-cell-${index}`}
                  event={event}
                  end
                />
              )
            }
            if (type === 'last_modified_time') {
              return (
                <DateTimeCell
                  key={`${event.id}-cell-${index}`}
                  event={event}
                  lastModified
                  time
                />
              )
            }
            if (type === 'date_published') {
              return (
                <DateTimeCell
                  key={`${event.id}-cell-${index}`}
                  event={event}
                  datePublished
                  time
                />
              )
            }
            if (type === 'event_time') {
              return (
                <DateTimeCell
                  key={`${event.id}-cell-${index}`}
                  event={event}
                  start
                  end
                />
              )
            }
            if (type === 'validation') {
              return (
                <ConnectedValidationCell
                  key={`${event.id}-cell-${index}`}
                  event={event}
                  handleInvalidRow={this.handleInvalidRow}
                />
              )
            }
            return undefined
          })}
        </TableRow>
        {shouldShow &&
          (isFetching ? (
            <TableRow
              className={
                tableColumns.includes('validation')
                  ? 'loading-row validation'
                  : 'loading-row'
              }
            >
              {tableColumns.reduce((acc, column, index) => {
                const tableHasCheckboxColumn = tableColumns.includes('checkbox')
                const columnCount = tableColumns.length

                // add a placeholder cell with "checkbox" class for tables with a checkbox column
                if (column === 'checkbox') {
                  return [
                    ...acc,
                    <TableCell
                      key={`cell-placeholder-${index}`}
                      className="checkbox"
                    />,
                  ]
                }
                // add loading spinner
                if (
                  (tableHasCheckboxColumn && acc.length === 1) ||
                  (!tableHasCheckboxColumn && acc.length === 0)
                ) {
                  return [
                    ...acc,
                    <TableCell key={`cell-placeholder-${index}`}>
                      <CircularProgress />
                    </TableCell>,
                  ]
                }
                // add empty cells to fill the row
                if (
                  (tableHasCheckboxColumn && index + 1 <= columnCount) ||
                  (!tableHasCheckboxColumn && index <= columnCount)
                ) {
                  return [
                    ...acc,
                    <TableCell
                      key={`cell-placeholder-${index}`}
                      className="placeholder-cell"
                    />,
                  ]
                }
                return acc
              }, [])}
            </TableRow>
          ) : (
            <SubEventsTable
              {...this.props}
              subEvents={subEvents}
              superEventIsChecked={checked}
              // nest level is used to calculate the indentation for each level of recursion
              nestLevel={nestLevel + 1}
            />
          ))}
      </React.Fragment>
    )
  }
}

// recursively render sub events of events
const SubEventsTable = (props) => {
  const { subEvents } = props

  return (
    <React.Fragment>
      {subEvents.map((event) => (
        <EventRow {...props} event={event} key={event.id} />
      ))}
    </React.Fragment>
  )
}

SubEventsTable.propTypes = {
  subEvents: PropTypes.array,
  superEventIsChecked: PropTypes.bool,
  event: PropTypes.object,
  nestLevel: PropTypes.number,
}

EventRow.defaultProps = {
  nestLevel: 1,
  selectedRows: [],
  invalidRows: [],
}

EventRow.propTypes = {
  subEvents: PropTypes.array,
  showSubEvents: PropTypes.bool,
  isSubEvent: PropTypes.bool,
  isSuperEvent: PropTypes.bool,
  superEventType: PropTypes.oneOf([
    SUPER_EVENT_TYPE_RECURRING,
    SUPER_EVENT_TYPE_UMBRELLA,
  ]),
  isFetching: PropTypes.bool,
  rowInvalid: PropTypes.bool,
  nestLevel: PropTypes.number,
  event: PropTypes.object,
  tableName: PropTypes.string,
  tableColumns: PropTypes.arrayOf(PropTypes.oneOf(constants.TABLE_COLUMNS)),
  selectedRows: PropTypes.array,
  invalidRows: PropTypes.array,
  handleRowSelect: PropTypes.func,
  handleInvalidRows: PropTypes.func,
  user: PropTypes.object,
  superEventIsChecked: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  user: state.user,
})

export const ConnectedEventRow = connect(mapStateToProps)(EventRow)
