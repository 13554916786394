import PropTypes from 'prop-types'

const TABLE_COLUMNS = [
  'checkbox',
  'name',
  'publisher',
  'start_time',
  'end_time',
  'last_modified_time',
  'date_published',
  'event_time',
  'validation',
]

export const constants = {
  API_HEADERS: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },

  APP_SET_FLASHMSG: 'APP_SET_FLASHMSG',
  APP_CLEAR_FLASHMSG: 'APP_CLEAR_FLASHMSG',

  RECEIVE_EVENT_FOR_EDITING: 'RECEIVE_EVENT_FOR_EDITING',

  IMAGES: {
    REQUEST_IMAGES: 'REQUEST_IMAGES',
    RECEIVE_IMAGES: 'RECEIVE_IMAGES',
    RECEIVE_IMAGES_ERROR: 'RECEIVE_IMAGES_ERROR',
    REQUEST_IMAGES_AND_META: 'REQUEST_IMAGES_AND_META',
    RECEIVE_IMAGES_AND_META: 'RECEIVE_IMAGES_AND_META',
    IMAGE_UPLOAD_SUCCESS: 'IMAGE_UPLOAD_SUCCESS',
    IMAGE_UPLOAD_ERROR: 'IMAGE_UPLOAD_ERROR',
  },

  SELECT_IMAGE_BY_ID: 'SELECT_IMAGE_BY_ID',

  RECEIVE_USERDATA: 'RECEIVE_USERDATA',
  CLEAR_USERDATA: 'CLEAR_USERDATA',

  EDITOR_RECEIVE_KEYWORDSETS: 'EDITOR_RECEIVE_KEYWORDSETS',
  EDITOR_RECEIVE_LANGUAGES: 'EDITOR_RECEIVE_LANGUAGES',
  EDITOR_SETDATA: 'EDITOR_SETDATA',
  EDITOR_UPDATE_SUB_EVENT: 'EDITOR_UPDATE_SUB_EVENT',
  EDITOR_SORT_SUB_EVENTS: 'EDITOR_SORT_SUB_EVENTS',
  EDITOR_ADD_OFFER: 'EDITOR_ADD_OFFER',
  EDITOR_DELETE_OFFER: 'EDITOR_DELETE_OFFER',
  EDITOR_SET_FREE_OFFERS: 'EDITOR_SET_FREE_OFFERS',
  EDITOR_SETLANGUAGES: 'EDITOR_SETLANGUAGES',
  EDITOR_REPLACEDATA: 'EDITOR_REPLACEDATA',
  EDITOR_CLEARDATA: 'EDITOR_CLEARDATA',
  EDITOR_SENDDATA_SUCCESS: 'EDITOR_SENDDATA_SUCCESS',
  SET_VALIDATION_ERRORS: 'SET_VALIDATION_ERRORS',
  SET_SERVER_ERRORS: 'SET_SERVER_ERRORS',
  VALIDATE_FOR: 'VALIDATE_FOR',

  ORG_FETCH_ADMIN: 'ORG_FETCH_ADMIN',
  ORG_FETCH_ADMIN_FAILED: 'ORG_FETCH_ADMIN_FAILED',
  ORG_FETCH_ADMIN_SUCCESS: 'ORG_FETCH_ADMIN_SUCCESS',

  // Local storage keys
  EDITOR_VALUES: 'EDITOR_VALUES',

  // Super event types

  // Note: if you are refactoring this, note that "single" was added later.
  // There are lots of logic comparing 'recurring' vs 'umbrella', and
  // those *usually* do not take 'single' into account.
  SUPER_EVENT_TYPE_SINGLE: 'single',
  SUPER_EVENT_TYPE_RECURRING: 'recurring',
  SUPER_EVENT_TYPE_UMBRELLA: 'umbrella',

  // Event schedule values
  EVENT_STATUS: {
    SCHEDULED: 'EventScheduled',
    CANCELLED: 'EventCancelled',
    POSTPONED: 'EventPostponed',
    RESCHEDULED: 'EventRescheduled',
  },

  PUBLICATION_STATUS: {
    DRAFT: 'draft',
    PUBLIC: 'public',
  },

  VALIDATION_STATUS: {
    CLEARED: 'cleared', // When form is cleared we also clear validation errors and set this status
    RESOLVE: 'resolve', // When form has validation errors and user is on editor page
  },

  EVENT_CREATION: {
    CREATE: 'create',
    EDIT: 'edit',
    DELETE: 'delete',
    CANCEL: 'cancel',
    POSTPONE: 'postpone',
    PUBLISH: 'publish',
    UPDATE: 'update',
    UPDATE_DRAFT: 'update-draft',
    SUCCESS: 'success',
    SAVE_DRAFT: 'savedraft',
    SAVE_PUBLIC: 'savepublic',
    CREATE_SUCCESS: 'event-creation-create-success',
    DELETE_SUCCESS: 'event-creation-delete-success',
    CANCEL_SUCCESS: 'event-creation-cancel-success',
    PUBLISH_SUCCESS: 'event-creation-publish-success',
    MULTIPLE_EVENTS_SUCCESS: 'event-creation-multipleevents-success',
    SAVE_PUBLIC_SUCCESS: 'event-creation-savepublic-success',
    SAVE_DRAFT_SUCCESS: 'event-creation-savedraft-success',
    DEFAULT_SUCCESS: 'event-creation-default-success',
    UPDATE_SUCCESS: 'event-creation-update-success',
  },

  VALIDATION_RULES: {
    IS_URL: 'isUrl',
    IS_DATE: 'isDate',
    IS_TIME: 'isTime',
    IS_INT: 'isInt',
    LONG_STRING: 'longString',
    MEDIUM_STRING: 'mediumString',
    SHORT_STRING: 'shortString',
    HAS_PRICE: 'hasPrice',
    REQUIRE_MULTI: 'requiredMulti',
    BEFORE_START_TIME: 'beforeStartTime',
    AFTER_START_TIME: 'afterStartTime',
    AFTER_ENROLMENT_START_TIME: 'afterEnrolmentStartTime',
    IN_THE_FUTURE: 'inTheFuture',
    DAY_WITHIN_INTERVAL: 'daysWithinInterval',
    REQUIRED_IN_CONTENT_LANGUAGE: 'requiredInContentLanguages',
    REQUIRED: 'required',
    REQUIRE_AT_ID: 'requiredAtId',
    AT_LEAST_ONE: 'atLeastOne',
    AT_LEAST_ONE_TOPIC: 'atLeastOneTopic',
    AT_LEAST_ONE_PLACE_KEYWORD: 'atLeastOnePlaceKeyword',
    IS_MORE_THAN_ONE: 'isMoreThanOne',
    AT_LEAST_ONE_IS_TRUE: 'atLeastOneIsTrue',
    DEFAULT_END_IN_FUTURE: 'defaultEndInTheFuture',
    REQUIRED_VIDEO_FIELD: 'requiredVideoField',
    IS_HOBBY_WITH_NO_AGE_LIMITS: 'isHobbyWithNoAgeLimits',
    IS_SERVER_ERROR: 'isServerError',
  },

  CHARACTER_LIMIT: {
    SHORT_STRING: 160,
    MEDIUM_STRING: 320,
    LONG_STRING: 15000,
  },

  USER_TYPE: {
    ADMIN: 'admin',
    REGULAR: 'regular',
  },

  TABLE_COLUMNS,

  TABLE_DATA_SHAPE: PropTypes.shape({
    events: PropTypes.array,
    count: PropTypes.number,
    paginationPage: PropTypes.number,
    pageSize: PropTypes.number,
    fetchComplete: PropTypes.bool,
    sortBy: PropTypes.string,
    sortDirection: PropTypes.string,
    tableColumns: PropTypes.arrayOf(PropTypes.oneOf(TABLE_COLUMNS)),
    selectedRows: PropTypes.array,
    invalidRows: PropTypes.array,
  }),

  pageSize: 1000,
} as const

// TODO: Should these be enums?
export type ImagesType = keyof typeof constants.IMAGES
export type UserType =
  (typeof constants.USER_TYPE)[keyof typeof constants.USER_TYPE]
export type PublicationStatus =
  (typeof constants.PUBLICATION_STATUS)[keyof typeof constants.PUBLICATION_STATUS]
export type EventStatus =
  (typeof constants.EVENT_STATUS)[keyof typeof constants.EVENT_STATUS]
export type ValidationStatus =
  (typeof constants.VALIDATION_STATUS)[keyof typeof constants.VALIDATION_STATUS]
export type EventCreation =
  (typeof constants.EVENT_CREATION)[keyof typeof constants.EVENT_CREATION]
